<template>
    <div>
        <template v-for="(u, index) in users">
            <span v-if="users.length > 0" v-html="u.text" :key="u.value" />
            <span v-if="users.length > 0" :key="index" v-show="index < users.length - 1">, </span>
        </template>
        <template v-if="users.length === 0"><span>-</span></template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'label-users-simple',
    data: () => ({}),
    created() {},
    mounted() {},
    props: {
        value: {},
    },
    watch: {},
    computed: {
        ...mapGetters(['usersLabels']),
        users() {
            if (this.value) {
                return OrderBy(
                    this.value.map((r) => {
                        let user = this.usersLabels.find((u) => u.name === r);
                        return {
                            text: user ? user.fullname : '-',
                            value: r,
                        };
                    }),
                    ['text'],
                    ['asc'],
                );
            }
            return [];
        },
    },
};
</script>
