<template>
    <v-card v-if="project && version" flat>
        <v-card-text v-if="loading">
            <v-progress-circular indeterminate color="white" :size="70"> </v-progress-circular>
        </v-card-text>
        <v-dialog v-model="displayFile" max-width="60%">
            <v-img contain height="100%" :src="`${fileToSee.url}?token=${token}`"></v-img>
        </v-dialog>

        <v-card-text>
            <v-list subheader two-line>
                <div v-for="category in categories" :key="category">
                    <v-subheader v-if="category" class="subtitle-1 light-blue--text text--darken-4">
                        <v-icon color="light-blue darken-4" left>mdi-arrow-right-circle</v-icon>
                        {{ category }}
                    </v-subheader>
                    <v-list-item v-for="file in filesByCategory(category)" :key="file.filename" @click="showFile(file)">
                        <v-list-item-content>
                            <v-list-item-title
                                class="subtitle-2"
                                v-text="`${file.filename} (${labelSize(file.size)})`"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                                v-text="formatDate(file.createdAt, 'dd/MM/yyyy HH:mm')"
                            ></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="!category" />
                </div>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/utils/api';
import { formatDateMixin } from '@/mixins/dates';

export default {
    name: 'delivery-files',
    data: () => ({
        loading: false,
        files: [],
        displayFile: false,
        fileToSee: {},
    }),
    mixins: [formatDateMixin],
    components: {},
    props: {
        version: {
            required: true,
        },
    },
    mounted() {
        if (this.version) {
            this.updateFiles();
        }
    },
    computed: {
        ...mapGetters(['project', 'token']),
        categories() {
            return [...new Set(this.files.map((f) => f.category))];
        },
    },
    methods: {
        async updateFiles() {
            this.loading = true;

            const gettingFiles = await API.get(`projects/${this.project.id}/versions/${this.version.id}/files`);

            this.files = gettingFiles.data.datas;

            this.$emit('total-files', this.files.length);

            this.loading = false;
        },
        filesByCategory(category) {
            return this.files.filter((f) => f.category === category);
        },
        showFile(file) {
            //Check if file is image
            const extension = file.filename.toLowerCase().split('.').pop();
            if (['png', 'jpg', 'jpeg'].includes(extension)) {
                this.displayFile = true;
                this.fileToSee = file;
            } else if (['pdf'].includes(extension)) {
                window.open(`${file.url}?token=${this.token}`, '_blank');
            } else {
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = `${location.origin}${file.url}?token=${this.token}&filename=${file.filename}`;
                a.setAttribute('download', file.filename);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        },
        labelSize(bytes) {
            const sizes = ['Bits', 'Ko', 'Mo', 'Go', 'To'];
            if (bytes == 0) return '0 Bit';
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
    },
};
</script>

<style></style>
