<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
        v-if="priorities"
    >
        <template v-slot:activator="{ on }">
            <v-chip small outlined :color="priority.color" v-on="on" v-text="priority.label"> </v-chip>
        </template>
        <v-card>
            <v-card-text>
                <priority-selector v-model="editingValue" :label="$t('issues.attributes.priority')" type="value" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import prioritySelector from '@/components/selectors/simple/priority';

export default {
    name: 'issue-priority',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    components: {
        prioritySelector,
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.priority;
            }
        },
    },
    computed: {
        ...mapGetters(['priorities', 'project']),
        priority() {
            return this.priorities.find((t) => t.value === this.issue.priority);
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'priority', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    priority: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
