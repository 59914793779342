<template>
    <v-select v-model="localModule" :items="modules" v-bind="$props" clearable></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'selector-module',
    data: () => ({
        localModule: undefined,
    }),
    created() {},
    mounted() {
        this.localModule = this.value;
    },
    props: {
        value: {},
        label: {},
        outlined: {
            default: false,
        },
        excludes: {
            default: () => [],
        },
    },
    watch: {
        localModule: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localModule = value;
        },
    },
    computed: {
        ...mapGetters(['project']),
        modules() {
            return OrderBy(
                this.project.modules
                    .filter((m) => !this.excludes.includes(m))
                    .map((m) => {
                        return {
                            value: m,
                            text: m,
                        };
                    }),
                ['value'],
                ['asc'],
            );
        },
    },
};
</script>
