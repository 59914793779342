<template>
    <v-row>
        <v-col cols="12" md="10" offset-md="1">
            <v-row v-if="showEditor">
                <v-col>
                    <editor v-model="internalValue"></editor>
                    <v-toolbar dense flat color="transparent">
                        <v-spacer />
                        <v-btn small color="red" text v-text="$t('buttons.cancel')" @click="cancelEdit" />
                        <v-btn small color="success" text v-text="$t('buttons.save')" @click="saveContent" />
                    </v-toolbar>
                </v-col>
            </v-row>
            <template v-if="!showEditor">
                <v-row v-if="!this.value" align="center" justify="center">
                    <v-btn v-if="allowEditing" small text v-text="buttonLabel" @click="showEditor = true"></v-btn>
                </v-row>
                <v-row v-if="this.value">
                    <v-col :cols="toc ? '10' : '12'">
                        <v-toolbar dense flat v-if="allowEditing" color="transparent">
                            <v-spacer />
                            <v-btn x-small text v-text="$t('buttons.edit')" @click="showEditor = true"></v-btn>
                        </v-toolbar>
                        <div v-html="content.content" :class="contentClass"></div>
                    </v-col>
                    <v-col v-if="toc" cols="2">
                        <div v-html="content.toc"></div>
                    </v-col>
                </v-row>
            </template>
        </v-col>
    </v-row>
</template>

<script>
import editor from './editor';
import { markdownIt } from '@/utils/markdown-render';
import { mapGetters } from 'vuex';

export default {
    name: 'editor-markdown',
    data() {
        return {
            showEditor: false,
            internalValue: this.value,
        };
    },
    components: {
        editor,
    },
    props: {
        value: {},
        label: {
            default: undefined,
        },
        allowEditing: {
            default: true,
        },
        dense: {
            default: false,
        },
        article: {
            default: false,
        },
        toc: {
            default: false,
        },
    },
    watch: {
        value: function (value) {
            this.internalValue = value;
        },
        showEditor: function (value) {
            this.$emit('show-editor', value);
        },
    },
    computed: {
        ...mapGetters(['token']),
        content() {
            if (this.value) {
                const env = {
                    token: this.token,
                };

                return markdownIt(this.value, env);
            } else {
                return { content: '', toc: '' };
            }
        },
        buttonLabel() {
            return this.label ? this.label : this.$t('buttons.edit');
        },
        contentClass() {
            let className = 'markdown-content';
            if (this.dense) {
                className = className + ' markdown-content-dense';
            }
            if (this.article) {
                className = className + ' markdown-content-article';
            }
            return className;
        },
    },
    methods: {
        cancelEdit() {
            this.internalValue = this.value;
            this.showEditor = false;
        },
        saveContent() {
            this.$emit('save-content', this.internalValue);
            this.cancelEdit();
        },
    },
};
</script>
<style>
.markdown-content p:last-child {
    margin-bottom: 0px !important;
}
.markdown-content h1,
.markdown-content h2,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5 {
    margin-top: 0.5rem !important;
    margin-bottom: 1.5rem !important;
}

.markdown-content code {
    padding: 0.25em 0.5em 0.25em !important;
}

.markdown-content pre {
    margin: 0.5rem 0;
}

.markdown-content img {
    max-width: 100%;
}

.markdown-content table {
    width: 100%;
}

.markdown-content hr {
    max-width: 80%;
    margin: 2rem auto !important;
    border-top: 1px solid rgba(0, 0, 0, 0.6);
}

.markdown-content ul {
    list-style: disc outside;
    margin-left: 2em;
    margin-top: 1em;
}
.markdown-content ul:not(:last-child) {
    margin-bottom: 1em;
}

.markdown-content .search-highlight {
    background-color: yellow;
}

.markdown-content h1 {
    font-size: 2rem !important;
}
.markdown-content h2 {
    font-size: 1.5rem !important;
}
.markdown-content h3 {
    font-size: 1.17rem !important;
}
.markdown-content h4 {
    font-size: 1rem !important;
}
.markdown-content h5 {
    font-size: 0.83rem !important;
}

.markdown-content-dense h1 {
    font-size: 1.5rem !important;
}
.markdown-content-dense h2 {
    font-size: 1.2rem !important;
}
.markdown-content-dense h3 {
    font-size: 1.1rem !important;
}
.markdown-content-dense h4 {
    font-size: 1rem !important;
}
.markdown-content-dense h5 {
    font-size: 0.83rem !important;
}

.markdown-content-article h1 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
}

.markdown-content-article h2 {
    margin-top: 1.5rem !important;
    font-weight: 400;
}

.markdown-content-article h3 {
    font-size: 1.25rem !important;
    margin-top: 1rem !important;
}

ul.revision-toc,
.revision-toc ul {
    list-style-type: none;
}

.revision-toc a {
    color: rgba(0, 0, 0, 0.6) !important;
    text-decoration: none !important;
}

.theme--dark .revision-toc a {
    color: rgba(255, 255, 255, 0.6) !important;
}

.revision-toc li {
    /*border-left: 2px solid #e5e5e5;*/
    padding-left: 0.5rem !important;
    padding-bottom: 0.25rem !important;
}

.revision-toc ul > li {
    border-left: 0;
}
</style>
