<template>
    <v-list-item dense class="px-0">
        <v-list-item-avatar v-if="avatar" :size="size" :color="!userToDisplay ? 'grey darken-2' : ''">
            <v-img v-if="userToDisplay" :src="userToDisplay.avatar"></v-img>
            <v-icon v-if="!userToDisplay" dark>mdi-account-circle</v-icon>
        </v-list-item-avatar>

        <v-list-item-content v-if="userToDisplay">
            <span v-if="short" v-html="shortName"></span>
            <span v-else v-html="userToDisplay.fullname"></span>
        </v-list-item-content>
        <v-list-item-content v-if="!userToDisplay">
            <span v-if="!this.value">-</span>
            <span v-if="this.value" v-text="$t('users.not_exist')"></span>
        </v-list-item-content>
    </v-list-item>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'label-user',
    data: () => ({}),
    created() {},
    mounted() {},
    props: {
        value: {},
        avatar: {
            default: false,
        },
        short: {
            default: false,
        },
        size: {
            default: 36,
        },
    },
    watch: {},
    computed: {
        ...mapGetters(['usersLabels']),
        userToDisplay() {
            return this.usersLabels.find((u) => u.name === this.value);
        },
        shortName() {
            return (
                this.userToDisplay.fullname.substring(0, 1) +
                '.' +
                this.userToDisplay.fullname.substring(
                    this.userToDisplay.fullname.indexOf(' ') + 1,
                    this.userToDisplay.fullname.length,
                )
            );
        },
    },
};
</script>
