<template>
    <div>
        <label-users v-if="!showNumber" :value="issue.interesteds" />
        <v-chip v-if="showNumber" label :color="colorChip" disabled>{{ issue.interesteds.length }}</v-chip>
    </div>
</template>
<script>
import labelUsers from '@/components/labels/simpleUsers';

export default {
    name: 'issue-interesteds',
    data: () => ({}),
    props: {
        issue: {},
        showNumber: {
            default: false,
        },
    },
    components: {
        labelUsers,
    },
    computed: {
        colorChip() {
            const clazz = 'blue-grey ';
            return this.$vuetify.theme.dark ? clazz + 'darken-5' : clazz + 'lighten-5';
        },
    },
};
</script>
