<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on }">
            <a :class="classTitle" v-on="on" v-text="issue.supportDetail ? issue.supportDetail : '-'"> </a>
        </template>
        <v-card>
            <v-card-text>
                <v-text-field
                    :label="$t('issues.attributes.supportDetail')"
                    v-if="editing"
                    v-model="editingValue"
                    single-line
                ></v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'issue-support-detail',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.supportDetail;
            }
        },
    },
    computed: {
        ...mapGetters(['project']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'supportDetail', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    supportDetail: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
