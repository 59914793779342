<template>
    <v-card v-if="project && version" flat>
        <v-card-text>
            <markdown
                :value="version.notes"
                v-on:save-content="changeAttribute"
                v-on:show-editor="showEmpty = !showEmpty"
                :label="$t('versions.actions.add_note')"
                :allow-editing="this.allowEditing"
            />
        </v-card-text>
        <v-card-text v-if="!version.notes && showEmpty" class="subtitle-1 py-8 text-center">
            {{ $t('versions.empty.note') }}
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import markdown from '@/components/shared/markdown';
import { projectMemberMixin } from '@/mixins/member';

export default {
    name: 'delivery-notes',
    data: () => ({
        editing: false,
        editingValue: undefined,
        showEmpty: true,
    }),
    mixins: [projectMemberMixin],
    components: {
        markdown,
    },
    props: {
        version: {
            required: true,
        },
        allowEdit: {
            default: true,
        },
    },
    computed: {
        ...mapGetters(['project']),
        allowEditing() {
            return this.allowEdit && this.member.roles.includes('developer');
        },
    },
    methods: {
        ...mapActions(['updateVersion']),
        changeAttribute(value) {
            const versionToUpdate = {
                id: this.project.id,
                versionId: this.version.id,
                notes: value,
            };

            this.updateVersion(versionToUpdate);
        },
    },
};
</script>

<style></style>
