<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on }">
            <a :class="classTitle" v-on="on" v-text="label"> </a>
        </template>
        <v-card>
            <v-card-text>
                <v-select :label="$t('issues.attributes.project')" :items="projectsToDisplay" v-model="editingValue" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';
export default {
    name: 'issue-project',

    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    mounted() {
        if (!this.projects.length > 1) {
            this.findProjects();
        }
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.project;
            }
        },
    },
    computed: {
        ...mapGetters(['projects', 'project']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
        projectsToDisplay() {
            return OrderBy(
                this.projects.map((p) => {
                    return {
                        value: p.name,
                        text: p.label,
                    };
                }),
                ['text'],
                ['asc'],
            );
        },
        label() {
            if (this.projects) {
                return this.projects.find((p) => p.name === this.issue.project).label;
            }
            return '';
        },
    },
    methods: {
        ...mapActions(['findProjects', 'updateIssue']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'project', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    project: this.editingValue,
                    module: '',
                    category: '',
                    versionImpact: [],
                    versionWanted: [],
                    versionTarget: [],
                    parent: null,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
