<template>
    <div>
        <markdown-editor
            :class="small ? 'editor-small' : ''"
            v-model="internalValue"
            ref="markdownEditor"
            :configs="config"
        ></markdown-editor>
    </div>
</template>

<script>
import markdownEditor from 'vue-simplemde';
import SimpleMDE from 'simplemde';
import { markdownIt } from '@/utils/markdown-render';
import 'simplemde/dist/simplemde.min.css';

export default {
    name: 'md-editor',
    data() {
        return {
            internalValue: this.value,
            config: {
                spellChecker: false,
                status: false,
                toolbar: [
                    {
                        name: 'bold',
                        className: 'mdi mdi-format-bold mdi-24px',
                        title: this.$t('editor.bold'),
                        action: SimpleMDE.toggleBold,
                    },
                    {
                        name: 'italic',
                        className: 'mdi mdi-format-italic mdi-24px',
                        title: this.$t('editor.italic'),
                        action: SimpleMDE.toggleItalic,
                    },
                    '|',
                    {
                        name: 'unordered-list',
                        className: 'mdi mdi-format-list-bulleted mdi-24px',
                        title: this.$t('editor.unordered-list'),
                        action: SimpleMDE.toggleUnorderedList,
                    },
                    {
                        name: 'ordered-list',
                        className: 'mdi mdi-format-list-numbered mdi-24px',
                        title: this.$t('editor.ordered-list'),
                        action: SimpleMDE.toggleOrderedList,
                    },
                    '|',
                    {
                        name: 'code',
                        className: 'mdi mdi-code-tags mdi-24px',
                        title: this.$t('editor.code'),
                        action: SimpleMDE.toggleCodeBlock,
                    },
                    '|',
                    {
                        name: 'table',
                        className: 'mdi mdi-table mdi-24px',
                        title: this.$t('editor.table'),
                        action: SimpleMDE.drawTable,
                    },
                    '|',
                    {
                        name: 'link',
                        className: 'mdi mdi-link-variant mdi-24px',
                        title: this.$t('editor.link'),
                        action: SimpleMDE.drawLink,
                    },
                    '|',
                    {
                        name: 'app-success',
                        className: 'mdi mdi-check-circle mdi-24px',
                        title: this.$t('editor.app-success'),
                        action: (editor) => {
                            this.myMDBlock(editor, '::: success\n', '\n:::');
                        },
                    },
                    {
                        name: 'app-info',
                        className: 'mdi mdi-information mdi-24px',
                        title: this.$t('editor.app-info'),
                        action: (editor) => {
                            this.myMDBlock(editor, '::: info\n', '\n:::');
                        },
                    },
                    {
                        name: 'app-warning',
                        className: 'mdi mdi-alert mdi-24px',
                        title: this.$t('editor.app-warning'),
                        action: (editor) => {
                            this.myMDBlock(editor, '::: warning\n', '\n:::');
                        },
                    },
                    {
                        name: 'app-important',
                        className: 'mdi mdi-alert-decagram mdi-24px',
                        title: this.$t('editor.app-important'),
                        action: (editor) => {
                            this.myMDBlock(editor, '::: important\n', '\n:::');
                        },
                    },
                    {
                        name: 'app-example',
                        className: 'mdi mdi-asterisk mdi-24px',
                        title: this.$t('editor.app-example'),
                        action: (editor) => {
                            this.myMDBlock(editor, '::: example\n', '\n:::');
                        },
                    },
                    {
                        name: 'app-config',
                        className: 'mdi mdi-cogs mdi-24px',
                        title: this.$t('editor.app-config'),
                        action: (editor) => {
                            this.myMDBlock(editor, '::: config\n', '\n:::');
                        },
                    },
                    '|',
                    {
                        name: 'side-by-side',
                        className: 'mdi mdi-format-horizontal-align-center mdi-24px',
                        title: this.$t('editor.side-by-side'),
                        action: SimpleMDE.toggleSideBySide,
                    },
                    {
                        name: 'fullscreen',
                        className: 'mdi mdi-arrow-all mdi-24px',
                        title: this.$t('editor.fullscreen'),
                        action: SimpleMDE.toggleFullScreen,
                    },
                ],
                previewRender: (plainText) => {
                    return markdownIt(plainText).content;
                },
            },
        };
    },
    components: {
        markdownEditor,
    },
    props: {
        value: {},
        small: {
            default: false,
        },
    },
    watch: {
        internalValue: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.internalValue = value;
            if (value === '') {
                this.$refs.markdownEditor.simplemde.codemirror.setValue(value);
            }
        },
    },
    computed: {},
    mounted() {
        this.showEditor = this.value ? true : false;
    },
    methods: {
        myMDBlock(editor, startChars, endChars) {
            if (/editor-preview-active/.test(editor.codemirror.getWrapperElement().lastChild.className)) {
                return;
            }

            endChars = typeof endChars === 'undefined' ? startChars : endChars;
            let cm = editor.codemirror;
            let start = startChars;
            let end = endChars;

            let startPoint = cm.getCursor('start');
            let endPoint = cm.getCursor('end');

            let text = cm.getSelection();
            cm.replaceSelection(start + text + end);

            startPoint.ch += startChars.length;
            endPoint.ch = startPoint.ch + text.length;

            cm.setSelection(startPoint, endPoint);
            cm.focus();
        },
    },
};
</script>
<style>
.editor-small .CodeMirror,
.editor-small .CodeMirror-scroll {
    min-height: 150px;
}

.theme--dark .editor-toolbar a {
    color: #ffffff !important;
}
.theme--dark .CodeMirror {
    background: #424242 !important;
    color: #ffffff !important;
}
.theme--dark .CodeMirror-cursor {
    border-left-color: #ffffff !important;
}
</style>
