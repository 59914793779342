var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.issue)?_c('div',[(_vm.allowEditing)?_c('v-card',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('selector-date',{attrs:{"label":_vm.$t('issues.tracking.attributes.date'),"editing":false},model:{value:(_vm.editTracking.date),callback:function ($$v) {_vm.$set(_vm.editTracking, "date", $$v)},expression:"editTracking.date"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('issues.tracking.attributes.time'),"suffix":"h"},model:{value:(_vm.editTracking.time),callback:function ($$v) {_vm.$set(_vm.editTracking, "time", $$v)},expression:"editTracking.time"}})],1),_c('v-col',[_c('selector-activity',{attrs:{"label":_vm.$t('issues.tracking.attributes.activity')},model:{value:(_vm.editTracking.activity),callback:function ($$v) {_vm.$set(_vm.editTracking, "activity", $$v)},expression:"editTracking.activity"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"success","outlined":""},on:{"click":_vm.updateTracking}},[_vm._v(" "+_vm._s(_vm.$t('buttons.updateTracking'))+" ")])],1)],1)],1),_c('v-card-title',{staticClass:"subtitle-2"},[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-toolbar-title',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$t('issues.tracking.total') + ' ' + _vm.totalTime + 'h')+" ")]),_c('v-spacer'),(_vm.issue.time)?_c('v-toolbar-title',{staticClass:"subtitle-1",class:_vm.deltaTime > 0 ? 'red--text text--darken-2' : 'light-green--text text--darken-2'},[_vm._v(" "+_vm._s(_vm.$t('issues.tracking.delta') + ' ' + _vm.deltaTime + 'h')+" ")]):_vm._e()],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tracking},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('label-date',{attrs:{"value":item.date,"format":"dd/MM/yyyy"}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('label-user',{attrs:{"value":item.createdBy}})]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.time)+" h ")]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('lists.timeActivity.' + item.activity))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(_vm.allowEditTracking(item))?_c('v-btn',{attrs:{"x-small":"","text":""},domProps:{"textContent":_vm._s(_vm.$t('buttons.edit'))},on:{"click":function($event){return _vm.editingTracking(item)}}}):_vm._e()]}}],null,true)})],1)],1):_c('span',[_vm._v(" "+_vm._s(_vm.totalTime + ' h')+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }