import { mapGetters } from 'vuex';

export const projectMemberMixin = {
    computed: {
        ...mapGetters(['project', 'profile']),
        member() {
            if (this.project) {
                return this.project.members.find((m) => m.user === this.profile.name);
            }
            return undefined;
        },
        memberRoles() {
            return this.member ? this.member.roles : [];
        },
    },
};
