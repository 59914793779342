<template>
    <v-list dense v-if="issue" color="transparent">
        <v-subheader
            class="text-uppercase"
            v-text="$t('issues.menu.attributes')"
            v-if="this.filtersAttributes.length === 0"
        />
        <v-list-item v-if="showAttribute('tracker')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.tracker')" />
            <v-list-item-content class="py-0 align-end">
                <issue-tracker
                    :issue="issue"
                    :display-value="true"
                    :allow-editing="allowEditingAttribute('tracker')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('status')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.status')" />
            <v-list-item-content class="py-0 align-end">
                <issue-status
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('status')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('severity')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.severity')" />
            <v-list-item-content class="py-0 align-end">
                <issue-severity
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('severity')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('priority')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.priority')" />
            <v-list-item-content class="py-0 align-end">
                <issue-priority
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('priority')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('module')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.module')" />
            <v-list-item-content class="py-0 align-end">
                <issue-module
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('module')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('category')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.category')" />
            <v-list-item-content class="py-0 align-end">
                <issue-category
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('category')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-subheader
            class="text-uppercase"
            v-text="$t('issues.menu.versions')"
            v-if="
                (showAttribute('versionImpact') || showAttribute('versionWanted') || showAttribute('versionWanted')) &&
                this.filtersAttributes.length === 0
            "
        />
        <v-list-item v-if="showAttribute('versionImpact')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.versionImpact')" />
            <v-list-item-content class="py-0 align-end">
                <issue-version-impact
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('versionImpact')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('versionWanted')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.versionWanted')" />
            <v-list-item-content class="py-0 align-end">
                <issue-version-wanted
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('versionWanted')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('versionTarget')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.versionTarget')" />
            <v-list-item-content class="py-0 align-end">
                <issue-version-target
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('versionTarget')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-subheader
            class="text-uppercase"
            v-text="$t('issues.menu.support')"
            v-if="this.filtersAttributes.length === 0"
        />
        <v-list-item v-if="showAttribute('supportClient')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.supportClient')" />
            <v-list-item-content class="py-0 align-end">
                <issue-supportClient
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('supportClient')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('supportInput')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.supportInput')" />
            <v-list-item-content class="py-0 align-end">
                <issue-supportInput
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('supportInput')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                    :key="issue.supportInput"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('supportOutput')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.supportOutput')" />
            <v-list-item-content class="py-0 align-end">
                <issue-supportOutput
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('supportOutput')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                    :key="issue.supportOutput"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('supportDetail')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.supportDetail')" />
            <v-list-item-content class="py-0 align-end">
                <issue-supportDetail
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('supportDetail')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>

        <v-subheader
            class="text-uppercase"
            v-text="$t('issues.menu.interventions')"
            v-if="this.filtersAttributes.length === 0"
        />
        <v-list-item v-if="showAttribute('assignedTo')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.assignedTo')" />
            <v-list-item-content class="py-0 align-end">
                <issue-assigned
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('assignedTo')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('time')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.time')" />
            <v-list-item-content class="py-0 align-end">
                <issue-time
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('time')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('done')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.done')" />
            <v-list-item-content class="py-0 align-end">
                <issue-done
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('done')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('startedAt')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.startedAt')" />
            <v-list-item-content class="py-0 align-end">
                <issue-started-at
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('startedAt')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('endedAt')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.endedAt')" />
            <v-list-item-content class="py-0 align-end">
                <issue-ended-at
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('endedAt')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-subheader
            v-if="
                (showAttribute('parent') || showAttribute('project') || showAttribute('sprint')) &&
                this.filtersAttributes.length === 0
            "
            class="text-uppercase"
            v-text="$t('issues.menu.structure')"
        />
        <v-list-item v-if="showAttribute('parent')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.parent')" />
            <v-list-item-content class="py-0 align-end">
                <issue-parent
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('parent')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                    :key="issue.parent"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('project')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.project')" />
            <v-list-item-content class="py-0 align-end">
                <issue-project
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('project')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                    :key="issue.project"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('sprint')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.sprint')" />
            <v-list-item-content class="py-0 align-end">
                <issue-sprint
                    :issue="issue"
                    :allow-editing="allowEditingAttribute('sprint')"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                    :key="issue.sprint"
                />
            </v-list-item-content>
        </v-list-item>
        <v-subheader
            v-if="(showAttribute('watchers') || showAttribute('interesteds')) && this.filtersAttributes.length === 0"
            class="text-uppercase"
            v-text="$t('issues.menu.activities')"
        />
        <v-list-item v-if="showAttribute('watchers')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.watchers')" />
            <v-list-item-content class="py-0 align-end">
                <issue-watchers
                    :issue="issue"
                    :allow-editing="true"
                    :only-edit="onlyEdit"
                    v-on:update-attribute="updateAttribute"
                />
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showAttribute('interesteds')">
            <v-list-item-content :class="classTitle" v-text="$t('issues.attributes.interesteds')" />
            <v-list-item-content class="py-0 align-end">
                <issue-interesteds :issue="issue" :only-edit="onlyEdit" v-on:update-attribute="updateAttribute" />
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import { projectMemberMixin } from '@/mixins/member';
import issueParent from '@/components/issues/parent';
import issueProject from '@/components/issues/project';
import issueTracker from '@/components/issues/tracker';
import issueSeverity from '@/components/issues/severity';
import issuePriority from '@/components/issues/priority';
import issueStatus from '@/components/issues/status';
import issueAssigned from '@/components/issues/assignedTo';
import issueModule from '@/components/issues/module';
import issueCategory from '@/components/issues/category';
import issueDone from '@/components/issues/done';
import issueTime from '@/components/issues/time';
import issueStartedAt from '@/components/issues/startedAt';
import issueEndedAt from '@/components/issues/endedAt';
import issueVersionTarget from '@/components/issues/versionTarget';
import issueVersionImpact from '@/components/issues/versionImpact';
import issueVersionWanted from '@/components/issues/versionWanted';
import issueWatchers from '@/components/issues/watchers';
import issueInteresteds from '@/components/issues/interesteds';
import issueSupportClient from '@/components/issues/supportClient';
import issueSupportInput from '@/components/issues/supportInput';
import issueSupportOutput from '@/components/issues/supportOutput';
import issueSupportDetail from '@/components/issues/supportDetail';
import issueSprint from '@/components/issues/sprint';

export default {
    name: 'issue-attributes',
    mixins: [projectMemberMixin],
    data: () => ({}),
    props: {
        issue: {},
        onlyEdit: {
            default: false,
        },
        filtersAttributes: {
            default: () => {
                return [];
            },
        },
        forceEdit: {
            default: undefined,
        },
    },
    components: {
        issueParent,
        issueTracker,
        issueSeverity,
        issuePriority,
        issueStatus,
        issueAssigned,
        issueModule,
        issueCategory,
        issueDone,
        issueTime,
        issueStartedAt,
        issueEndedAt,
        issueVersionTarget,
        issueVersionImpact,
        issueVersionWanted,
        issueWatchers,
        issueInteresteds,
        issueSupportClient,
        issueSupportInput,
        issueSupportOutput,
        issueSupportDetail,
        issueProject,
        issueSprint,
    },
    computed: {
        ...mapGetters(['project', 'profile', 'trackers']),
        attributesHidden() {
            if (this.issue) {
                return this.trackers.find((t) => t.name === this.issue.tracker).hiddenAttributes;
            }
            return [];
        },
        classTitle() {
            const clazz = 'font-weight-medium blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-3' : clazz + 'text--darken-3';
        },
    },
    methods: {
        showAttribute(name) {
            const allowByTracker = !this.attributesHidden.includes(name);
            let allowForCreation = true;

            if (!this.issue.id) {
                allowForCreation = this.project.fields.creation.includes(name);
            }

            if (this.filtersAttributes.length > 0) {
                allowForCreation = this.filtersAttributes.includes(name);
            }

            if (['category'].includes(name)) {
                return this.issue.module && allowByTracker && allowForCreation;
            }
            if (['supportInput', 'supportOutput', 'supportDetail'].includes(name)) {
                return this.issue.supportClient && allowByTracker && allowForCreation;
            }
            if (['versionImpact', 'versionTarget', 'versionWanted'].includes(name)) {
                return allowByTracker && allowForCreation && this.project.versions.length > 0;
            }

            if (['assignedTo'].includes(name)) {
                const allowByRoles = this.memberRoles.some((r) =>
                    ['productOwner', 'productManager', 'developer'].includes(r),
                );
                return allowByRoles && allowByTracker && allowForCreation;
            }

            if (['startedAt', 'endedAt', 'done'].includes(name)) {
                const allowByRoles = this.memberRoles.some((r) =>
                    ['productOwner', 'productManager', 'developer'].includes(r),
                );

                return this.issue.assignedTo && allowByTracker && allowForCreation && allowByRoles;
            }

            if (name === 'watchers') {
                const allowByRoles = this.memberRoles.some((r) =>
                    ['productOwner', 'productManager', 'developer'].includes(r),
                );
                return allowForCreation && (allowByRoles || this.issue.createdBy === this.profile.name);
            }

            if (['interesteds', 'project'].includes(name)) {
                return allowForCreation && this.memberRoles.some((r) => ['productOwner', 'productManager'].includes(r));
            }

            if (['sprint'].includes(name)) {
                return (
                    allowForCreation &&
                    this.memberRoles.some((r) => ['productOwner', 'productManager', 'developer'].includes(r))
                );
            }

            return allowForCreation && allowByTracker;
        },
        allowEditingAttribute(name) {
            const allowByRoles = this.memberRoles.some((r) =>
                ['productOwner', 'productManager', 'developer'].includes(r),
            );

            if (this.forceEdit) {
                return this.forceEdit;
            }

            if (['severity', 'supportOutput', 'supportDetail', 'versionTarget'].includes(name)) {
                return allowByRoles;
            }

            if (!this.issue.id) {
                return true;
            }

            return allowByRoles || this.issue.createdBy === this.profile.name;
        },
        updateAttribute(attribute) {
            this.$emit('update-attribute', attribute);
            this.$forceUpdate();
        },
    },
};
</script>

<style></style>
