<template>
    <v-container :class="classContainer">
        <v-row v-if="!editing">
            <v-toolbar dense flat color="transparent">
                <v-btn v-if="displayWatch" icon @click="watchIssue">
                    <v-icon v-if="isWatcher" color="amber darken-2">mdi-star</v-icon>
                    <v-icon v-else>mdi-star-outline</v-icon>
                </v-btn>
                <v-tooltip top dark color="grey darken-4" :disabled="!(limitSize && title.length > limitSize)">
                    <template v-slot:activator="{ on: tooltip }">
                        <a
                            @click="editValue"
                            v-if="!linked"
                            v-on="{ ...tooltip }"
                            v-text="
                                limitSize && title.length > limitSize ? title.substring(0, limitSize) + '...' : title
                            "
                            :class="classTitle"
                        />
                        <a
                            v-if="linked"
                            v-on="{ ...tooltip }"
                            v-text="
                                limitSize && title.length > limitSize ? title.substring(0, limitSize) + '...' : title
                            "
                            @click="openIssue"
                            :class="classTitle"
                        />
                    </template>
                    <span v-text="title"></span>
                </v-tooltip>

                <v-spacer />
                <v-btn v-if="displayLike" icon @click="likeIssue">
                    <v-icon v-if="isLike" color="primary">mdi-thumb-up</v-icon>
                    <v-icon v-else>mdi-thumb-up-outline</v-icon>
                </v-btn>
            </v-toolbar>
        </v-row>
        <v-row v-if="editing">
            <v-col>
                <v-card flat>
                    <v-card-text>
                        <ValidationProvider
                            :name="$t('issues.attributes.name')"
                            :rules="'required'"
                            v-slot="{ errors }"
                        >
                            <v-text-field
                                :label="$t('issues.attributes.name')"
                                v-if="editing"
                                v-model="editingValue"
                                single-line
                                counter="80"
                                :error-messages="errors[0]"
                                color="red"
                            ></v-text-field>
                        </ValidationProvider>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions v-if="!onlyEdit">
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                        <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'issue-name',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        linked: {
            default: false,
        },
        displayLike: {
            default: false,
        },
        displayWatch: {
            default: false,
        },
        displayNumber: {
            default: true,
        },
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
        classContainer: {
            default: 'py-0',
        },
        limitSize: {
            default: undefined,
        },
        displayModule: {
            default: false,
        },
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.name;
            }
        },
        editingValue: function (e) {
            if (this.onlyEdit) {
                this.$emit('input', e);
            }
        },
    },
    mounted() {
        if (this.onlyEdit) {
            this.editing = true;
        }
    },
    computed: {
        ...mapGetters(['profile', 'projects']),
        classTitle() {
            let clazz = 'issue-title ';
            if (this.linked) {
                clazz += 'blue--text ';
            } else {
                clazz += 'blue-grey--text ';
            }
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
        isWatcher() {
            return this.issue.watchers.includes(this.profile.name);
        },
        isLike() {
            return this.issue.interesteds.includes(this.profile.name);
        },
        title() {
            const moduleTitle =
                this.issue.module && this.displayModule ? '[' + this.issue.module.toUpperCase() + '] ' : '';

            const title = this.displayNumber ? '#' + this.issue.number + ' ' : '';
            return title + moduleTitle + this.issue.name;
        },
    },
    methods: {
        ...mapActions([
            'updateIssue',
            'addWatcherToIssue',
            'deleteWatcherToIssue',
            'addInterestedToIssue',
            'deleteInterestedToIssue',
        ]),
        editValue() {
            if (this.allowEditing) {
                this.editing = true;
            }
        },
        async changeAttribute(value) {
            await this.updateIssue({
                id: this.issue.id,
                name: this.editingValue,
            });

            this.editing = false;
            this.$emit('change-attribute-value');
        },
        openIssue() {
            const project = this.projects.find((p) => p.name === this.issue.project);
            this.$router.push({
                name: 'project-issue',
                params: { project_id: project.id, issue_id: this.issue.id },
            });
        },
        async watchIssue() {
            if (this.isWatcher) {
                await this.deleteWatcherToIssue({
                    id: this.issue.id,
                    name: this.profile.name,
                });
            } else {
                await this.addWatcherToIssue({
                    id: this.issue.id,
                    name: this.profile.name,
                });
            }

            this.$emit('change-attribute-value');
        },
        async likeIssue() {
            if (this.isLike) {
                await this.deleteInterestedToIssue({
                    id: this.issue.id,
                    name: this.profile.name,
                });
            } else {
                await this.addInterestedToIssue({
                    id: this.issue.id,
                    name: this.profile.name,
                });
            }

            this.$emit('change-attribute-value');
        },
    },
};
</script>

<style scoped>
.issue-title {
    word-break: break-word;
}
</style>
