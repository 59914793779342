<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on }">
            <a :class="classTitle" v-on="on" v-text="label"> </a>
        </template>
        <v-card>
            <v-card-text>
                <category-selector
                    v-model="editingValue"
                    :label="$t('issues.attributes.category')"
                    :filter-module="issue.module"
                />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import categorySelector from '@/components/selectors/simple/category';

export default {
    name: 'issue-category',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
        displayPath: {
            default: false,
        },
    },
    components: {
        categorySelector,
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.category;
            }
        },
    },
    computed: {
        ...mapGetters(['project']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
        label() {
            let label = '';
            if (this.displayPath && this.issue.module) {
                label = this.issue.module;
            }

            if (label && this.issue.category) {
                label += ' - ' + this.issue.category;
            } else {
                label = this.issue.category;
            }

            if (!label) {
                label = '-';
            }

            return label;
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'category', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    category: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
