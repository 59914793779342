<template>
    <v-card v-if="issue" :class="cardClass">
        <v-card-text class="subtitle-2">
            <v-row>
                <v-col cols="3">
                    <issue-project :issue="issue" :allowEditing="false" />
                </v-col>
                <v-col>
                    <issue-name :issue="issue" :linked="true" :allowEditing="false" />
                </v-col>
            </v-row>
        </v-card-text>
        <div class="d-flex justify-center">
            <v-btn text @click="displayAttributes = !displayAttributes" small>
                <template v-if="displayAttributes"> {{ $t('views.actions.minus') }} </template>
                <template v-if="!displayAttributes"> {{ $t('views.actions.plus') }} </template>
            </v-btn>
        </div>
        <v-card-text v-show="displayAttributes">
            <issue-attributes :issue="issue" :filters-attributes="filtersAttributes" :force-edit="false" />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import issueAttributes from './attributes.vue';
import issueName from '@/components/issues/name';
import issueProject from '@/components/issues/project';

export default {
    name: 'issue-card',
    data: () => ({
        displayAttributes: false,
    }),
    props: {
        issue: {
            required: true,
        },
        filtersAttributes: {
            default: () => {
                return [];
            },
        },
    },
    components: {
        issueName,
        issueProject,
        issueAttributes,
    },
    computed: {
        cardClass() {
            const color = this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-5';
            return 'pt-2 ' + color;
        },
    },
};
</script>
