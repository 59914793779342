<template>
    <v-select v-model="localSeverity" :items="severitiesToDisplay" v-bind="$props"></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'selector-severity',
    data: () => ({
        localSeverity: undefined,
    }),
    created() {},
    mounted() {
        this.localSeverity = this.value;
    },
    props: {
        value: {},
        label: {},
        outlined: {
            default: false,
        },
        type: {
            default: 'id',
        },
    },
    watch: {
        localSeverity: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localSeverity = value;
        },
    },
    computed: {
        ...mapGetters(['severities']),
        severitiesToDisplay() {
            return OrderBy(
                this.severities.map((t) => {
                    return {
                        value: this.type === 'id' ? t.id : t.value,
                        text: t.label,
                    };
                }),
                ['value'],
                ['asc'],
            );
        },
    },
};
</script>
