<template>
    <v-select v-model="localSprint" :items="sprints" :label="label" hide-details single-line clearable></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';
import { formatDateMixin } from '@/mixins/dates';
import { parseISO } from 'date-fns';

export default {
    name: 'selector-sprint',
    data: () => ({
        localSprint: undefined,
    }),
    created() {},
    mounted() {
        this.localSprint = this.value;

        if (this.initActiveSprint && !this.value) {
            const initSprint = this.sprints.find((s) => s.status === 'active');
            if (initSprint) {
                this.localSprint = initSprint.value;
            }
        }
    },
    mixins: [formatDateMixin],
    props: {
        value: {},
        label: {},
        project: {
            default: undefined,
        },
        initActiveSprint: {
            default: false,
        },
        closedSprint: {
            default: true,
        },
    },
    watch: {
        localSprint: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localSprint = value;
        },
    },
    computed: {
        ...mapGetters(['teams']),
        sprints() {
            return OrderBy(
                this.teams
                    .filter((t) => {
                        if (this.project) {
                            return t.projects.includes(this.project);
                        }
                        return true;
                    })
                    .map((t) => t.sprints)
                    .reduce((a, b) => a.concat(b), [])
                    .filter((s) => {
                        if (!this.closedSprint) {
                            return s.status !== 'close';
                        } else {
                            return true;
                        }
                    })
                    .map((s) => {
                        return {
                            status: s.status,
                            value: s.name,
                            text:
                                s.name +
                                ' (' +
                                this.formatDate(parseISO(s.startedAt), 'dd MMM') +
                                ' - ' +
                                this.formatDate(parseISO(s.endedAt), 'dd MMM') +
                                ')',
                        };
                    }),
                ['text'],
                ['asc'],
            );
        },
    },
};
</script>
