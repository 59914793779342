<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on }">
            <a :class="classTitle" v-on="on">
                <label-users v-if="!showNumber" :value="issue.watchers" />
                <v-chip v-if="showNumber" label :color="colorChip" disabled>{{ issue.watchers.length }}</v-chip>
            </a>
        </template>
        <v-card>
            <v-card-text>
                <selector-users v-model="editingValue" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import selectorUsers from '@/components/selectors/multiple/usersProject';
import labelUsers from '@/components/labels/simpleUsers';

export default {
    name: 'issue-watchers',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        displayValue: {
            default: false,
        },
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
        showNumber: {
            default: false,
        },
    },
    components: {
        selectorUsers,
        labelUsers,
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.watchers;
            }
        },
    },
    computed: {
        ...mapGetters(['project']),
        colorChip() {
            const clazz = 'blue-grey ';
            return this.$vuetify.theme.dark ? clazz + 'darken-5' : clazz + 'lighten-5';
        },
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
    },
    methods: {
        ...mapActions(['addWatcherToIssue']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'watchers', value: this.editingValue });
                this.editing = false;
            } else {
                await this.addWatcherToIssue({
                    id: this.issue.id,
                    name: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
