<template>
    <v-select
        v-model="localUser"
        :items="usersItems"
        :outlined="outlined"
        :label="label"
        :clearable="allowRemove"
    ></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'selector-user',
    data: () => ({
        localUser: undefined,
    }),
    created() {
        if (this.users.length === 0) {
            this.findUsers();
        }
    },
    mounted() {
        this.localUser = this.value;
    },
    props: {
        value: {},
        label: {},
        outlined: {
            default: false,
        },
        creator: {
            default: undefined,
        },
        allowRemove: {
            default: false,
        },
    },
    watch: {
        localUser: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localUser = value;
        },
    },
    computed: {
        ...mapGetters(['projectUsers', 'users', 'profile']),
        usersItems() {
            let users = [
                {
                    value: this.profile.name,
                    text: this.$t('users.connected'),
                },
            ];
            if (this.creator) {
                users.push({
                    value: this.creator,
                    text: this.$t('users.creator'),
                });
            }
            users = users.concat(this.projectUsers);
            return users;
        },
    },
    methods: {
        ...mapActions(['findUsers']),
    },
};
</script>
