<template>
    <v-select
        v-model="localCategory"
        :items="categories"
        item-value="name"
        item-text="name"
        :label="label"
        clearable
    ></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'selector-category',
    data: () => ({
        localCategory: undefined,
    }),
    created() {},
    mounted() {
        this.localCategory = this.value;
    },
    props: {
        value: {},
        label: {},
        filterModule: {
            default: undefined,
        },
        excludes: {
            default: () => [],
        },
    },
    watch: {
        localCategory: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localCategory = value;
        },
    },
    computed: {
        ...mapGetters(['project']),
        categories() {
            return OrderBy(
                this.project.categories
                    .filter((c) => !this.excludes.includes(c.name))
                    .filter((c) => {
                        if (this.filterModule) {
                            return c.modules.includes(this.filterModule);
                        }

                        return true;
                    }),
                ['name'],
                ['asc'],
            );
        },
    },
};
</script>
