let container = require('markdown-it-container');

module.exports = function alertsPlugin(md, options) {
    let containerOpenCount = 0;
    let links = options ? options.links : true;
    init();

    function setupContainer(name) {
        md.use(container, name, {
            render: function (tokens, idx) {
                if (tokens[idx].nesting === 1) {
                    let title = tokens[idx].info.substring(name.length + 2, tokens[idx].info.length);
                    containerOpenCount += 1;
                    let icon = 'mdi-information';
                    let color = 'info';
                    if (name === 'success') {
                        icon = 'mdi-check-circle';
                        color = 'success';
                    }
                    if (name === 'warning') {
                        icon = 'mdi-alert-circle';
                        color = 'warning';
                    }
                    if (name === 'important') {
                        icon = 'mdi-alert';
                        color = 'error';
                    }
                    if (name === 'example') {
                        icon = 'mdi-asterisk';
                        color = 'blue-grey';
                    }
                    if (name === 'config') {
                        icon = 'mdi-cog';
                        color = 'deep-purple';
                    }

                    let content = `<div class="v-alert v-sheet theme--light v-alert--dense v-alert--outlined ${color}--text py-0 px-2"><div class="v-alert__wrapper my-4">
                    <i class='v-icon notranslate v-alert__icon mdi ${icon} theme--light ${color}--text'></i>
                    <div class="v-alert__content">`;
                    if (title) {
                        content = content + '<strong>' + title + '</strong>\n';
                    }
                    return content;
                } else {
                    containerOpenCount -= 1;
                    return '</div></div></div>\n';
                }
            },
        });
    }

    function isContainerOpen() {
        return containerOpenCount > 0;
    }

    function selfRender(tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    }

    function setupLinks() {
        var defaultRender = md.renderer.rules.link_open || selfRender;

        md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
            if (isContainerOpen()) {
                tokens[idx].attrPush(['class', 'alert-link']);
            }

            return defaultRender(tokens, idx, options, env, self);
        };
    }

    function init() {
        setupContainer('success');
        setupContainer('info');
        setupContainer('warning');
        setupContainer('important');
        setupContainer('example');
        setupContainer('config');

        if (links) {
            setupLinks();
        }
    }
};
