<template>
    <v-autocomplete
        v-model="localVersions"
        :items="versions"
        :label="label"
        multiple
        chips
        deletable-chips
    ></v-autocomplete>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'selector-versions',
    data: () => ({
        localVersions: [],
    }),
    created() {},
    mounted() {
        this.localVersions = this.value;
    },
    props: {
        value: {},
        label: {},
        versionStatus: {
            default: undefined,
        },
        inDevelop: {
            default: undefined,
        },
    },
    watch: {
        localVersions: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localVersions = value;
        },
    },
    computed: {
        ...mapGetters(['project']),
        versions() {
            const versions = this.project.versions.filter((v) => {
                let validate = true;
                if (this.versionStatus) {
                    validate = validate && v.status === this.versionStatus;
                }
                if (this.inDevelop) {
                    validate = validate && v.development === this.inDevelop;
                }
                return validate;
            });
            return versions
                .map((v) => {
                    return { text: v.name, value: v.name };
                })
                .sort((a, b) => {
                    const aName = a.value.replace(/\d+/g, (n) => +n + 100000);
                    const bName = b.value.replace(/\d+/g, (n) => +n + 100000);
                    return aName.localeCompare(bName);
                })
                .reverse();
        },
    },
};
</script>
