var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selected.length > 0)?_c('v-alert',{attrs:{"border":"left","color":"cyan darken-3","outlined":""}},[_c('div',{staticClass:"title"},[_vm._v("Demandes sélectionnées")]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.selected),function(i){return _c('v-chip',{key:i.id,attrs:{"close":"","color":"cyan darken-3","label":"","outlined":"","small":""},on:{"click":function($event){return _vm.removeSelected(i)},"click:close":function($event){return _vm.removeSelected(i)}}},[_vm._v(" #"+_vm._s(i.number)+" ")])}),1)],1):_vm._e(),(_vm.view && !_vm.displayCard)?_c('v-data-table',{class:!_vm.showHeader ? 'table-no-header' : '',attrs:{"calculate-widths":true,"headers":_vm.viewCols,"items":_vm.issues,"server-items-length":_vm.totalIssues,"loading":_vm.loading,"footer-props":{
            'items-per-page-options': [10, 20, 50, 100, 200],
            'show-current-page': true,
            'show-first-last-page': true,
        },"show-select":_vm.allowSelect,"hide-default-footer":!_vm.showFooter,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.project",fn:function(ref){
        var item = ref.item;
return [_c('issue-project',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('project')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.number",fn:function(ref){
        var item = ref.item;
return [_c('issue-number',{attrs:{"issue":item}})]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('issue-name',{attrs:{"issue":item,"linked":true,"allow-editing":_vm.allowEditingAttribute('name')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.tracker",fn:function(ref){
        var item = ref.item;
return [_c('issue-tracker',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('tracker'),"display-value":true},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.severity",fn:function(ref){
        var item = ref.item;
return [_c('issue-severity',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('severity')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.priority",fn:function(ref){
        var item = ref.item;
return [_c('issue-priority',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('priority')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('issue-status',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('status')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.assignedTo",fn:function(ref){
        var item = ref.item;
return [_c('issue-assigned',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('assignedTo')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.module",fn:function(ref){
        var item = ref.item;
return [_c('issue-module',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('module')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.category",fn:function(ref){
        var item = ref.item;
return [_c('issue-category',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('category')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.createdBy",fn:function(ref){
        var item = ref.item;
return [_c('label-user',{attrs:{"value":item.createdBy,"avatar":false,"size":24}})]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('issue-created-at',{attrs:{"issue":item}})]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [_c('issue-updated-at',{attrs:{"issue":item}})]}},{key:"item.startedAt",fn:function(ref){
        var item = ref.item;
return [_c('issue-started-at',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('startedAt')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.endedAt",fn:function(ref){
        var item = ref.item;
return [_c('issue-ended-at',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('endedAt')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.versionImpact",fn:function(ref){
        var item = ref.item;
return [_c('issue-version-impact',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('versionImpact')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.versionTarget",fn:function(ref){
        var item = ref.item;
return [_c('issue-version-target',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('versionTarget')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.done",fn:function(ref){
        var item = ref.item;
return [_c('issue-done',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('done')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.time",fn:function(ref){
        var item = ref.item;
return [_c('issue-time',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('time')},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.interesteds",fn:function(ref){
        var item = ref.item;
return [_c('issue-interesteds',{attrs:{"issue":item,"allow-editing":false,"show-number":true},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.watchers",fn:function(ref){
        var item = ref.item;
return [_c('issue-watchers',{attrs:{"issue":item,"allow-editing":false,"show-number":true},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.tracking",fn:function(ref){
        var item = ref.item;
return [_c('issue-trackings',{attrs:{"issue":item,"allow-editing":false}})]}},{key:"item.sprint",fn:function(ref){
        var item = ref.item;
return [_c('issue-sprint',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('sprint'),"project":item.project},on:{"change-attribute-value":_vm.updateView}})]}},{key:"item.@categoryPath",fn:function(ref){
        var item = ref.item;
return [_c('issue-category',{attrs:{"issue":item,"allow-editing":_vm.allowEditingAttribute('category'),"display-path":true},on:{"change-attribute-value":_vm.updateView}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.displayCard)?_c('v-container',_vm._l((_vm.issues),function(item){return _c('v-row',{key:item.id},[_c('v-col',[_c('issue-card',{attrs:{"issue":item,"filters-attributes":_vm.view.cols}})],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }