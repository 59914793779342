import Plugin from 'markdown-it-regexp';
import kebabCase from 'lodash/kebabCase';

export default () => {
    return Plugin(/\[\[([\w\W\s-./]+)(\|([\w\W\s-./]+))?\]\]/, (match) => {
        let label = '';
        const link = match[1].split('/').map(kebabCase).join('/');
        const doc = window.location.pathname.split('/');

        if (window.location.pathname.includes('docs')) {
            if (match[3]) {
                label = match[3];
            } else {
                label = match[1].trim().split('/');
                label = label[label.length - 1];
            }
            return `<a class="doc-article-link" href="${doc.slice(0, 6).join('/')}/${link}">${label}</a>`;
        } else {
            return match[1];
        }
    });
};
