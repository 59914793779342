<template>
    <v-select v-model="localPriority" :items="prioritiesToDisplay" v-bind="$props"></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'selector-priority',
    data: () => ({
        localPriority: undefined,
    }),
    created() {},
    mounted() {
        this.localPriority = this.value;
    },
    props: {
        value: {},
        label: {},
        outlined: {
            default: false,
        },
        type: {
            default: 'id',
        },
    },
    watch: {
        localPriority: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localPriority = value;
        },
    },
    computed: {
        ...mapGetters(['priorities']),
        prioritiesToDisplay() {
            return OrderBy(
                this.priorities.map((t) => {
                    return {
                        value: this.type === 'id' ? t.id : t.value,
                        text: t.label,
                    };
                }),
                ['text'],
                ['asc'],
            );
        },
    },
};
</script>
