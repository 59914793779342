<template>
    <div>
        <v-alert v-if="selected.length > 0" border="left" color="cyan darken-3" outlined>
            <div class="title">Demandes sélectionnées</div>
            <v-chip-group column>
                <v-chip
                    v-for="i in selected"
                    :key="i.id"
                    close
                    color="cyan darken-3"
                    label
                    outlined
                    small
                    @click="removeSelected(i)"
                    @click:close="removeSelected(i)"
                >
                    #{{ i.number }}
                </v-chip>
            </v-chip-group>
        </v-alert>
        <v-data-table
            v-if="view && !displayCard"
            v-model="selected"
            :calculate-widths="true"
            :headers="viewCols"
            :items="issues"
            :server-items-length="totalIssues"
            :loading="loading"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100, 200],
                'show-current-page': true,
                'show-first-last-page': true,
            }"
            :show-select="allowSelect"
            :hide-default-footer="!showFooter"
            :class="!showHeader ? 'table-no-header' : ''"
            :options.sync="options"
        >
            <template v-slot:[`item.project`]="{ item }">
                <issue-project
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('project')"
                />
            </template>
            <template v-slot:[`item.number`]="{ item }">
                <issue-number :issue="item" />
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <issue-name
                    :issue="item"
                    :linked="true"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('name')"
                />
            </template>
            <template v-slot:[`item.tracker`]="{ item }">
                <issue-tracker
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('tracker')"
                    :display-value="true"
                />
            </template>
            <template v-slot:[`item.severity`]="{ item }">
                <issue-severity
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('severity')"
                />
            </template>
            <template v-slot:[`item.priority`]="{ item }">
                <issue-priority
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('priority')"
                />
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <issue-status
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('status')"
                />
            </template>
            <template v-slot:[`item.assignedTo`]="{ item }">
                <issue-assigned
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('assignedTo')"
                />
            </template>
            <template v-slot:[`item.module`]="{ item }">
                <issue-module
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('module')"
                />
            </template>
            <template v-slot:[`item.category`]="{ item }">
                <issue-category
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('category')"
                />
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <label-user :value="item.createdBy" :avatar="false" :size="24" />
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                <issue-created-at :issue="item" />
            </template>
            <template v-slot:[`item.updatedAt`]="{ item }">
                <issue-updated-at :issue="item" />
            </template>
            <template v-slot:[`item.startedAt`]="{ item }">
                <issue-started-at
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('startedAt')"
                />
            </template>
            <template v-slot:[`item.endedAt`]="{ item }">
                <issue-ended-at
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('endedAt')"
                />
            </template>
            <template v-slot:[`item.versionImpact`]="{ item }">
                <issue-version-impact
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('versionImpact')"
                />
            </template>
            <template v-slot:[`item.versionTarget`]="{ item }">
                <issue-version-target
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('versionTarget')"
                />
            </template>
            <template v-slot:[`item.done`]="{ item }">
                <issue-done
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('done')"
                />
            </template>
            <template v-slot:[`item.time`]="{ item }">
                <issue-time
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('time')"
                />
            </template>
            <template v-slot:[`item.interesteds`]="{ item }">
                <issue-interesteds
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="false"
                    :show-number="true"
                />
            </template>
            <template v-slot:[`item.watchers`]="{ item }">
                <issue-watchers
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="false"
                    :show-number="true"
                />
            </template>
            <template v-slot:[`item.tracking`]="{ item }">
                <issue-trackings :issue="item" :allow-editing="false" />
            </template>
            <template v-slot:[`item.sprint`]="{ item }">
                <issue-sprint
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('sprint')"
                    :project="item.project"
                />
            </template>
            <template v-slot:[`item.@categoryPath`]="{ item }"
                ><issue-category
                    :issue="item"
                    v-on:change-attribute-value="updateView"
                    :allow-editing="allowEditingAttribute('category')"
                    :display-path="true"
                />
            </template>
        </v-data-table>

        <v-container v-if="displayCard">
            <v-row v-for="item in issues" :key="item.id">
                <v-col>
                    <issue-card :issue="item" :filters-attributes="view.cols" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { issuesHeaders } from '@/config/views';
import { API } from '@/utils/api';
import { projectMemberMixin } from '@/mixins/member';
import labelUser from '@/components/labels/user';
import issueProject from '@/components/issues/project';
import issueNumber from '@/components/issues/number';
import issueName from '@/components/issues/name';
import issueTracker from '@/components/issues/tracker';
import issueSeverity from '@/components/issues/severity';
import issuePriority from '@/components/issues/priority';
import issueStatus from '@/components/issues/status';
import issueAssigned from '@/components/issues/assignedTo';
import issueModule from '@/components/issues/module';
import issueCategory from '@/components/issues/category';
import issueDone from '@/components/issues/done';
import issueTime from '@/components/issues/time';
import issueCreatedAt from '@/components/issues/createdAt';
import issueUpdatedAt from '@/components/issues/updatedAt';
import issueStartedAt from '@/components/issues/startedAt';
import issueEndedAt from '@/components/issues/endedAt';
import issueVersionTarget from '@/components/issues/versionTarget';
import issueVersionImpact from '@/components/issues/versionImpact';
import issueInteresteds from '@/components/issues/interesteds';
import issueWatchers from '@/components/issues/watchers';
import issueTrackings from '@/components/issues/trackings';
import issueSprint from '@/components/issues/sprint';
import issueCard from '../card';

export default {
    name: 'issues-view',
    mixins: [projectMemberMixin],
    data: () => ({
        issues: [],
        totalIssues: -1,
        loading: false,
        selected: [],
        options: {},
    }),
    components: {
        labelUser,
        issueProject,
        issueNumber,
        issueName,
        issueTracker,
        issueSeverity,
        issuePriority,
        issueStatus,
        issueAssigned,
        issueModule,
        issueCategory,
        issueDone,
        issueCreatedAt,
        issueUpdatedAt,
        issueStartedAt,
        issueEndedAt,
        issueVersionTarget,
        issueVersionImpact,
        issueTime,
        issueInteresteds,
        issueWatchers,
        issueTrackings,
        issueSprint,
        issueCard,
    },
    computed: {
        ...mapGetters(['project']),
    },
    mounted() {
        this.initContext();
        if (this.view) {
            this.updateView();
        }
    },
    props: {
        view: {
            required: true,
        },
        filters: {
            default: () => [],
        },
        cols: {
            default: () => [],
        },
        group: {
            default: undefined,
        },
        allowSelect: {
            default: true,
        },
        showHeader: {
            default: true,
        },
        showFooter: {
            default: true,
        },
        allowEditing: {
            default: true,
        },
        displayCard: {
            default: false,
        },
        addColsToView: {
            default: false,
        },
    },
    watch: {
        view: function () {
            if (this.options.page === 1) {
                this.updateView();
            }
            this.options.page = 1;
            this.options.sortBy = [];
            this.options.sortDesc = [];
        },
        options: function (value) {
            window.sessionStorage.setItem(this.sessionContextVariable, JSON.stringify(value));
            this.updateView();
        },
        filters: function () {
            this.updateView();
        },
        group: function (value, old) {
            if (value !== old) {
                this.options.page = 1;
            }
            if (this.view.groupCol) {
                this.updateView();
            }
        },
        selected: function (value) {
            this.$emit('update-selected', value);
        },
        issues: function (value) {
            this.$emit('getted-issues', value);
        },
        totalIssues: function (value) {
            this.$emit('total-issues', value);
        },
    },
    computed: {
        ...mapGetters(['project']),
        sessionContextVariable() {
            return `${this.$route.path}#${this.view.id}/context`;
        },
        viewCols() {
            if (this.view) {
                const vCols = this.view.cols.map((c) => {
                    const col = Object.assign({}, issuesHeaders.filter((h) => h.value === c)[0]);
                    col.text = this.showHeader ? this.$t(col.text) : '';
                    return col;
                });

                if (this.addColsToView && this.cols) {
                    return [
                        ...vCols,
                        ...this.cols.map((c) => {
                            const col = Object.assign({}, issuesHeaders.filter((h) => h.value === c)[0]);
                            col.text = this.showHeader ? this.$t(col.text) : '';
                            return col;
                        }),
                    ];
                } else {
                    return vCols;
                }
            }

            return [];
        },
        viewColsNames() {
            return this.viewCols.map((v) => v.value);
        },
    },
    methods: {
        initContext() {
            const options = window.sessionStorage.getItem(this.sessionContextVariable);
            if (options) {
                this.options = JSON.parse(options);
            }
        },
        async updateView() {
            this.loading = true;

            const bodyCall = {
                cols: [...this.cols],
            };
            const query = [].concat(this.filters);

            if (!this.viewColsNames.includes('module')) {
                bodyCall.cols.push('module');
            }

            if (this.group !== undefined && this.view.groupCol) {
                query.push({
                    field: this.view.groupCol,
                    operator: '$eq',
                    value: this.group,
                });
            }

            bodyCall.filters = query;

            if (this.options.sortBy && this.options.sortBy.length > 0) {
                const order = {
                    field: this.options.sortBy[0],
                    sort: this.options.sortDesc[0] ? 1 : -1,
                };

                bodyCall.order = order;
            }

            let call = `${this.view.url}/issues`;
            if (this.showFooter) {
                call = call + `?limit=${this.options.itemsPerPage}&page=${this.options.page}`;
            }

            const gettingIssues = await API.post(call, bodyCall);

            this.totalIssues = gettingIssues.data.total;
            this.issues = gettingIssues.data.datas;

            this.loading = false;
        },
        allowEditingAttribute(name) {
            return (
                this.allowEditing &&
                this.memberRoles.some((r) => ['productOwner', 'productManager', 'developer'].includes(r))
            );
        },
        clearSelected() {
            this.selected = [];
        },
        removeSelected(issue) {
            this.selected = this.selected.filter((i) => i.id !== issue.id);
        },
    },
};
</script>

<style>
.table-no-header th {
    height: 0px !important;
}
</style>
