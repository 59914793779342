<template>
    <div v-if="issue">
        <v-card v-if="allowEditing" flat>
            <v-dialog v-model="dialog" max-width="800px">
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <selector-date
                                        v-model="editTracking.date"
                                        :label="$t('issues.tracking.attributes.date')"
                                        :editing="false"
                                    />
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        :label="$t('issues.tracking.attributes.time')"
                                        v-model="editTracking.time"
                                        suffix="h"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <selector-activity
                                        v-model="editTracking.activity"
                                        :label="$t('issues.tracking.attributes.activity')"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small color="success" outlined @click="updateTracking">
                            {{ $t('buttons.updateTracking') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-card-title class="subtitle-2">
                <v-toolbar dense flat color="transparent">
                    <v-toolbar-title class="grey--text">
                        {{ $t('issues.tracking.total') + ' ' + totalTime + 'h' }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-title
                        v-if="issue.time"
                        class="subtitle-1"
                        :class="deltaTime > 0 ? 'red--text text--darken-2' : 'light-green--text text--darken-2'"
                    >
                        {{ $t('issues.tracking.delta') + ' ' + deltaTime + 'h' }}
                    </v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headers" :items="tracking">
                    <template v-slot:[`item.date`]="{ item }">
                        <label-date :value="item.date" format="dd/MM/yyyy" />
                    </template>
                    <template v-slot:[`item.createdBy`]="{ item }">
                        <label-user :value="item.createdBy" />
                    </template>
                    <template v-slot:[`item.time`]="{ item }"> {{ item.time }} h </template>
                    <template v-slot:[`item.activity`]="{ item }">
                        {{ $t('lists.timeActivity.' + item.activity) }}
                    </template>
                    <template v-slot:[`item.id`]="{ item }">
                        <v-btn
                            v-if="allowEditTracking(item)"
                            x-small
                            text
                            v-text="$t('buttons.edit')"
                            @click="editingTracking(item)"
                        ></v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <span v-else>
            {{ totalTime + ' h' }}
        </span>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import labelDate from '@/components/labels/date';
import labelUser from '@/components/labels/user';
import selectorDate from '@/components/selectors/simple/date';
import selectorActivity from '@/components/selectors/simple/timeActivity';
import Clone from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

export default {
    name: 'issue-tracking',
    data: () => ({
        dialog: false,
        editTracking: {
            date: new Date(),
        },
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
    },
    components: { labelDate, labelUser, selectorDate, selectorActivity },
    computed: {
        ...mapGetters(['profile']),
        headers() {
            return [
                { text: this.$t('issues.tracking.attributes.date'), value: 'date' },
                { text: this.$t('issues.tracking.attributes.createdBy'), value: 'createdBy' },
                { text: this.$t('issues.tracking.attributes.time'), value: 'time' },
                { text: this.$t('issues.tracking.attributes.activity'), value: 'activity' },
                { text: '', value: 'id' },
            ];
        },
        totalTime() {
            return this.tracking.map((t) => t.time).reduce((a, b) => a + b, 0);
        },
        tracking() {
            return sortBy(this.issue.tracking, ['date', 'createdAt'], ['asc', 'asc']);
        },
        deltaTime() {
            return this.totalTime - this.issue.time;
        },
    },
    methods: {
        ...mapActions(['updateTrackingToIssue']),
        allowEditTracking(tracking) {
            return tracking.createdBy === this.profile.name;
        },
        editingTracking(tracking) {
            this.editTracking = Clone(tracking);
            this.editTracking.date = new Date(this.editTracking.date);
            this.dialog = true;
        },
        async updateTracking() {
            this.editTracking.time = parseFloat(this.editTracking.time);
            await this.updateTrackingToIssue({
                id: this.issue.id,
                tracking: this.editTracking,
            });
            this.dialog = false;
            this.$emit('change-attribute-value');
        },
    },
};
</script>
