<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
        v-if="status && trackers"
    >
        <template v-slot:activator="{ on }">
            <v-chip label small outlined :color="issueStatus.color" v-on="on" v-text="issueStatus.label"> </v-chip>
        </template>
        <v-card>
            <v-card-text>
                <v-select :label="$t('issues.attributes.status')" :items="statusToDisplay" v-model="editingValue" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn icon @click="seeAllStatus" v-if="memberRoles.some((r) => ['productManager'].includes(r))">
                    <v-icon v-if="filtering">mdi-eye-off</v-icon>
                    <v-icon v-else>mdi-eye</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { projectMemberMixin } from '@/mixins/member';
export default {
    name: 'issue-status',
    mixins: [projectMemberMixin],
    data: () => ({
        editing: false,
        editingValue: undefined,
        filtering: true,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.status;
            }
        },
    },
    computed: {
        ...mapGetters(['trackers', 'status', 'project']),
        issueStatus() {
            return this.status.find((t) => t.name === this.issue.status);
        },
        statusToDisplay() {
            const tracker = this.trackers.find((t) => t.name === this.issue.tracker);
            let allowStatus = this.issueStatus.next.concat(this.issueStatus.id);
            let disableStatus = tracker ? tracker.hiddenStatus : [];

            if (!this.filtering) {
                allowStatus = this.status.map((s) => s.id);
                disableStatus = [];
            }

            return this.project
                ? this.status
                      .filter((s) => !disableStatus.includes(s.id))
                      .filter((s) => allowStatus.includes(s.id))
                      .map((s) => {
                          return {
                              value: s.name,
                              text: s.label,
                          };
                      })
                : [];
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        seeAllStatus() {
            if (this.filtering) {
                this.filtering = undefined;
            } else {
                this.filtering = true;
            }
        },
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'status', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    status: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
