<template>
    <v-container v-if="groups.length > 0">
        <div v-if="showBadge" class="d-flex flex-wrap justify-center pt-4">
            <v-badge bordered color="error" :content="total" offset-x="26" offset-y="10">
                <v-btn
                    small
                    dark
                    text
                    :color="groupColor('all')"
                    v-text="$t('issues.all')"
                    class="mb-4 mx-4"
                    @click="showGroup('all')"
                />
            </v-badge>
            <v-badge
                bordered
                color="error"
                :content="g.count"
                v-for="g in groups"
                :key="g.group"
                offset-x="26"
                offset-y="10"
            >
                <v-btn
                    small
                    dark
                    text
                    :color="groupColor(g.group)"
                    v-text="g.label"
                    @click="showGroup(g.group)"
                    class="mb-4 mx-4"
                />
            </v-badge>
        </div>
        <template v-if="!showBadge">
            <v-row class="flex-nowrap">
                <v-col v-for="g in groups" :key="g.group" cols="3">
                    <h5 class="text-h5 overline text-center" v-text="`${g.label} (${g.count})`"></h5>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/utils/api';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'project-issues-group',
    data: () => ({
        group: 'all',
        groups: [],
    }),
    props: {
        view: {
            required: true,
        },
        filters: {
            default: () => [],
        },
        showBadge: {
            default: true,
        },
    },
    watch: {
        view: function (value) {
            this.updateGroups();
        },
        filters: function (value) {
            this.updateGroups();
        },
    },
    components: {},
    mounted() {
        if (this.view) {
            this.updateGroups();
        }
    },
    computed: {
        ...mapGetters(['project', 'usersLabels', 'projects']),
        sessionVariable() {
            return `${this.$route.path}#${this.view.id}/group`;
        },
        total() {
            return this.groups.map((g) => g.count).reduce((a, b) => a + b, 0);
        },
    },
    methods: {
        initGroup() {
            const group = window.sessionStorage.getItem(this.sessionVariable);
            if (group) {
                this.group = JSON.parse(group);
            }
        },
        groupColor(g) {
            const disabledColor = this.$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-2';
            return this.group === g ? 'primary' : disabledColor;
        },
        async updateGroups() {
            const group = window.sessionStorage.getItem(this.sessionVariable);
            if (group) {
                this.showGroup(JSON.parse(group));
            } else {
                this.showGroup('all');
            }
            const gettingGroups = await API.post(`${this.view.url}/groups`, {
                filters: this.filters,
            });

            const groupWithLabel = gettingGroups.data.datas.map((g) => {
                return {
                    group: g.group ? g.group : '',
                    count: g.count,
                    label: g.group ? this.labelGroup(g.group) : this.$t('views.group_empty'),
                };
            });

            this.groups = OrderBy(groupWithLabel, ['label'], ['asc']);
            this.$emit('getted-groups', this.groups);
        },
        labelGroup(group) {
            const attribute = this.view.groupCol;
            if (attribute === 'tracker') {
                return this.$t('lists.trackers.' + group);
            }
            if (attribute === 'status') {
                return this.$t('lists.status.' + group);
            }
            if (attribute === 'severity') {
                return this.$t('lists.severities.' + group);
            }
            if (attribute === 'priority') {
                return this.$t('lists.priorities.' + group);
            }
            if (attribute === 'assignedTo' || attribute === 'createdBy') {
                const user = this.usersLabels.find((u) => u.name === group);
                return user ? user.fullname : group;
            }
            if (attribute === 'project') {
                return this.projects.find((p) => p.name === group).label;
            }

            return group;
        },
        showGroup(group) {
            this.group = group;
            this.$emit('update-group', group === 'all' ? undefined : group);

            window.sessionStorage.setItem(this.sessionVariable, JSON.stringify(this.group));
        },
    },
};
</script>

<style></style>
