<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on: menu }">
            <v-toolbar dense flat color="transparent" class="pa-0">
                <v-tooltip top dark color="grey darken-4" :disabled="!issue.parent">
                    <template v-slot:activator="{ on: tooltip }">
                        <a :class="classTitle" v-on="{ ...tooltip, ...menu }" v-text="label"></a>
                    </template>
                    <span v-text="description"></span>
                </v-tooltip>
                <v-spacer />
                <v-icon v-if="issue.parent && description" small @click="goToIssue">mdi-open-in-new</v-icon>
            </v-toolbar>
        </template>
        <v-card>
            <v-card-text>
                <v-autocomplete
                    :loading="isLoading"
                    :label="$t('issues.attributes.parent')"
                    :search-input.sync="search"
                    :items="items"
                    v-model="editingValue"
                    clearable
                />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'issue-parent',

    data: () => ({
        editing: false,
        editingValue: undefined,
        isLoading: false,
        search: '',
        items: [],
        label: '-',
        description: undefined,
    }),
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    mounted() {
        if (this.issue.parent) {
            this.findIssueLabel(this.issue.parent).then((r) => {
                const i = r.data.datas[0];
                this.label = `#${i.number}`;
                this.description = i.name;

                this.items.push({
                    value: this.issue.parent,
                    text: `#${i.number} ${i.name}`,
                });
            });
        }
    },
    watch: {
        editing(e) {
            if (e) {
                this.editingValue = this.issue.parent;
            }
        },
        search(value) {
            if (isNaN(value)) {
                return;
            }

            const search = {
                query: value,
                project: this.project.name,
                issue: this.issue.id,
            };

            // Items have already been requested
            if (this.isLoading) return;

            this.isLoading = true;

            this.searchIssues(search)
                .then((response) => {
                    if (response.data.success) {
                        this.items = response.data.datas.map((i) => {
                            return {
                                text: `#${i.number} ${i.name}`,
                                value: i.id,
                            };
                        });
                    }
                })
                .finally(() => (this.isLoading = false));
        },
    },
    computed: {
        ...mapGetters(['project']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
    },
    methods: {
        ...mapActions(['updateIssue', 'searchIssues', 'findIssueLabel']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'parent', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    parent: this.editingValue ? this.editingValue : null,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
        goToIssue() {
            this.$router.push({
                name: 'project-issue',
                params: { project_id: this.$route.params.project_id, issue_id: this.issue.parent },
            });
        },
    },
};
</script>
