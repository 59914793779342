<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
        v-if="trackers"
    >
        <template v-slot:activator="{ on: menu }">
            <v-tooltip top dark color="grey darken-4" :disabled="!tracker.description">
                <template v-slot:activator="{ on: tooltip }">
                    <v-chip
                        small
                        label
                        :color="tracker.color"
                        v-on="{ ...tooltip, ...menu }"
                        v-text="displayValue ? tracker.label : ''"
                        dark
                        class="ipo-max-chip"
                    />
                </template>
                <span v-text="tracker.description"></span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-text>
                <tracker-selector
                    :label="$t('issues.attributes.tracker')"
                    v-model="editingValue"
                    type="name"
                    :allowRoles="profileRoles"
                />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import trackerSelector from '@/components/selectors/simple/tracker';

export default {
    name: 'issue-tracker',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        displayValue: {
            default: false,
        },
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    components: {
        trackerSelector,
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.tracker ? this.issue.tracker : this.trackers[0].name;
            }
        },
    },
    computed: {
        ...mapGetters(['trackers', 'project', 'profile']),
        tracker() {
            return this.trackers.find((t) => t.name === this.issue.tracker);
        },
        profileRoles() {
            if (this.project) {
                return this.project.members.find((m) => m.user === this.profile.name).roles;
            }
            return [];
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'tracker', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    tracker: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
<style scoped>
.ipo-max-chip {
    justify-content: center;
    width: 100%;
}
</style>
