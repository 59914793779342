<template>
    <a :class="classTitle" v-text="'#' + issue.number" @click="openIssue"></a>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'issue-number',
    data: () => ({}),
    props: {
        issue: {},
    },
    computed: {
        ...mapGetters(['projects']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
    },
    methods: {
        openIssue() {
            const project = this.projects.find((p) => p.name === this.issue.project);
            this.$router.push({
                name: 'project-issue',
                params: { project_id: project.id, issue_id: this.issue.id },
            });
        },
    },
};
</script>
