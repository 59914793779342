import 'highlight.js/styles/tomorrow-night-blue.css';

let twemoji = require('twemoji').default;
let md = require('markdown-it')({
    replaceLink: (link, env) => {
        let redmine = link.match('^issue#');
        if (redmine) {
            let id = /^issue#(\d{1,})/.exec(link)[1];
            return '/issues/' + id + '';
        }

        const files = link.match('^/api');
        if (files) {
            link = link + '?token=' + env.token;
        }

        return link;
    },
});
md.use(require('./markdown-it-links').default());
md.use(require('./markdown-it-alerts'));
md.use(require('markdown-it-mark'));
md.use(require('markdown-it-replace-link'));
md.use(require('markdown-it-highlightjs'));
md.use(require('markdown-it-toc-and-anchor').default, {
    anchorLink: false,
    tocLastLevel: 2,
    tocClassName: 'revision-toc',
});

md.use(require('markdown-it-emoji'));

md.renderer.rules.emoji = function (token, idx) {
    return twemoji.parse(token[idx].content);
};

export let markdownIt = (content, env = {}) => {
    const render = {};
    const toc = {
        tocCallback: (tocMarkdown, tocArray, tocHtml) => {
            render.toc = tocHtml;
        },
    };
    render.content = md.render(content, Object.assign(env, toc));
    return render;
};
