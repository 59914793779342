<template>
    <v-row>
        <v-col cols="10" offset="1">
            <v-row>
                <v-col cols="12" md="10" offset-md="1">
                    <v-toolbar flat>
                        <h5 class="text-h5 overline mr-4">{{ $t('versions.migration.from') }}</h5>
                        <v-select v-model="startVersion" :items="versions" single-line hide-details />
                        <v-spacer />
                        <v-spacer />
                        <h5 class="text-h5 overline mr-4">{{ $t('versions.migration.to') }}</h5>
                        <v-select v-model="endVersion" :items="endVersions" single-line hide-details />
                    </v-toolbar>
                </v-col>
            </v-row>
            <v-row v-if="startVersion && endVersion">
                <v-col cols="12" md="10" offset-md="1">
                    <v-card>
                        <v-tabs centered grow>
                            <v-tab href="#version-journal">
                                <v-icon>mdi-clipboard-text-outline</v-icon>
                                {{ $t('versions.sections.journal') }}
                            </v-tab>
                            <v-tab href="#version-note">
                                <v-icon>mdi-ballot-outline</v-icon>
                                {{ $t('versions.sections.note') }}
                            </v-tab>
                            <v-tab href="#version-files">
                                <v-icon>mdi-attachment</v-icon>
                                {{ $t('versions.sections.files') }}
                            </v-tab>

                            <v-tab-item value="version-journal">
                                <v-card-text v-show="issues > 0">
                                    <issues-groups
                                        v-if="displayGroup"
                                        :view.sync="view"
                                        :filters.sync="filters"
                                        v-on:update-group="updateGroup"
                                    />
                                    <issues-view
                                        :view.sync="view"
                                        :filters.sync="filters"
                                        :group.sync="group"
                                        :allow-select="false"
                                        :show-footer="false"
                                        v-on:total-issues="updateTotalIssues"
                                        :show-header="false"
                                        :allow-editing="false"
                                    />
                                </v-card-text>
                            </v-tab-item>
                            <v-tab-item value="version-note">
                                <div
                                    v-for="version in migrationsVersions"
                                    :key="version.id"
                                    class="pa-4"
                                    v-show="version.notes"
                                >
                                    <v-subheader
                                        v-text="version.label ? 'Version ' + version.label : 'Version ' + version.name"
                                        class="overline text-h4 justify-center text--darken-4 light-blue--text"
                                    ></v-subheader>
                                    <version-notes :version="version" :allow-edit="false" />
                                </div>
                            </v-tab-item>
                            <v-tab-item value="version-files">
                                <div v-for="version in migrationsVersions" :key="version.id" class="pa-4">
                                    <v-subheader
                                        v-text="version.label ? 'Version ' + version.label : 'Version ' + version.name"
                                        class="overline text-h4 justify-center text--darken-4 light-blue--text"
                                    ></v-subheader>
                                    <version-files :version="version" />
                                </div>
                            </v-tab-item>
                        </v-tabs>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderBy from 'lodash/orderBy';
import issuesGroups from '../../issues/issue/groups';
import issuesView from '../../issues/issue/view';
import versionNotes from './versions/notes';
import versionFiles from './versions/files';

export default {
    name: 'project-migration',
    data: () => ({
        startVersion: undefined,
        endVersion: undefined,
        displayGroup: true,
        group: undefined,
        issues: 0,
    }),
    components: {
        issuesGroups,
        issuesView,
        versionNotes,
        versionFiles,
    },
    computed: {
        ...mapGetters(['project']),
        view() {
            return this.project.views.find((v) => v.name === 'deliveryMigration');
        },
        versions() {
            if (this.project) {
                const versions = this.project.versions
                    .filter((v) => !v.development)
                    .map((v) => {
                        return {
                            value: v.id,
                            text: v.label ? v.label : v.name,
                            name: v.name,
                        };
                    });

                return versions
                    .sort((a, b) => {
                        const aName = a.name.replace(/\d+/g, (n) => +n + 100000);
                        const bName = b.name.replace(/\d+/g, (n) => +n + 100000);
                        return aName.localeCompare(bName);
                    })
                    .reverse();
            }
            return [];
        },
        endVersions() {
            const index = this.versions.findIndex((v) => {
                return v.value === this.startVersion;
            });
            return this.versions.slice(0, index);
        },
        migrationsVersions() {
            if (this.startVersion && this.endVersion) {
                const endIndex = this.versions.findIndex((v) => {
                    return v.value === this.startVersion;
                });

                const startIndex = this.versions.findIndex((v) => {
                    return v.value === this.endVersion;
                });

                const allowVersions = this.versions.slice(startIndex, endIndex + 1).map((v) => v.value);
                return this.project.versions
                    .filter((v) => allowVersions.includes(v.id))
                    .sort((a, b) => {
                        const aName = a.name.replace(/\d+/g, (n) => +n + 100000);
                        const bName = b.name.replace(/\d+/g, (n) => +n + 100000);
                        return aName.localeCompare(bName);
                    })
                    .reverse();
            }
            return [];
        },
        filters() {
            return [
                {
                    field: 'versionTarget',
                    operator: '$in',
                    value: this.migrationsVersions.map((v) => v.name).join(','),
                },
            ];
        },
    },
    methods: {
        updateGroup(group) {
            this.group = group;
        },
        updateTotalIssues(count) {
            this.issues = count;
        },
    },
};
</script>

<style></style>
