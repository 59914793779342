<template>
    <v-select v-model="localTracker" :items="trackersToDisplay" v-bind="$props"></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'selector-tracker',
    data: () => ({
        localTracker: undefined,
    }),
    created() {},
    mounted() {
        this.localTracker = this.value;
    },
    props: {
        value: {},
        label: {},
        outlined: {
            default: false,
        },
        allowRoles: {
            default: () => {
                return [];
            },
        },
        type: {
            default: 'id',
        },
        singleLine: {
            default: false,
        },
        dense: {
            default: false,
        },
        clearable: {
            default: false,
        },
        prependIcon: {
            default: undefined,
        },
    },
    watch: {
        localTracker: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localTracker = value;
        },
    },
    computed: {
        ...mapGetters(['trackers']),
        trackersToDisplay() {
            return OrderBy(
                this.trackers
                    .filter((t) =>
                        this.allowRoles.length > 0 ? t.roles.some((r) => this.allowRoles.includes(r)) : true,
                    )
                    .map((t) => {
                        return {
                            value: this.type === 'id' ? t.id : t.name,
                            text: t.label,
                        };
                    }),
                ['text'],
                ['asc'],
            );
        },
    },
};
</script>
