<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on }">
            <a
                :class="classTitle"
                v-on="on"
                v-html="
                    issue.versionImpact && issue.versionImpact.length > 0 ? issue.versionImpact.join(', &nbsp;') : '-'
                "
            >
            </a>
        </template>
        <v-card>
            <v-card-text>
                <versions-selector :label="$t('issues.attributes.versionImpact')" v-model="editingValue" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import versionsSelector from '@/components/selectors/multiple/versions';

export default {
    name: 'issue-version-impact',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        displayValue: {
            default: false,
        },
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    components: {
        versionsSelector,
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.versionImpact;
            }
        },
    },
    computed: {
        ...mapGetters(['project']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'versionImpact', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    versionImpact: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
