<template>
    <v-select v-model="localActivity" :items="activities" v-bind="$props"></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'selector-timeActivity',
    data: () => ({
        localActivity: undefined,
    }),
    created() {},
    mounted() {
        this.localActivity = this.value;
    },
    props: {
        value: {},
        label: {},
        outlined: {
            default: false,
        },
    },
    watch: {
        localActivity: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localActivity = value;
        },
    },
    computed: {
        ...mapGetters(['timeActivity']),
        activities() {
            return OrderBy(
                this.timeActivity.map((a) => {
                    return {
                        value: a,
                        text: this.$t('lists.timeActivity.' + a),
                    };
                }),
                ['text'],
                ['asc'],
            );
        },
    },
};
</script>
