<template>
    <span v-text="formatDate(issue.createdAt, 'dd/MM/yyyy HH:mm')"></span>
</template>
<script>
import { formatDateMixin } from '@/mixins/dates';
export default {
    name: 'issue-createdAt',
    data: () => ({}),
    mixins: [formatDateMixin],
    props: {
        issue: {},
    },
};
</script>
