export const issuesHeaders = [
    {
        value: 'number',
        text: 'issues.attributes.number',
        sortable: true,
        align: 'center',
    },
    {
        value: 'project',
        text: 'issues.attributes.project',
        sortable: true,
        align: 'center',
    },
    {
        value: 'tracker',
        text: 'issues.attributes.tracker',
        sortable: true,
        width: '30',
        align: 'center',
    },
    {
        value: 'priority',
        text: 'issues.attributes.priority',
        sortable: true,
        align: 'center',
    },
    {
        value: 'severity',
        text: 'issues.attributes.severity',
        sortable: true,
        align: 'center',
    },
    {
        value: 'name',
        text: 'issues.attributes.name',
        sortable: true,
        width: '50%',
    },
    {
        value: 'status',
        text: 'issues.attributes.status',
        sortable: true,
        align: 'center',
    },
    {
        value: 'module',
        text: 'issues.attributes.module',
        sortable: true,
        align: 'center',
    },
    {
        value: 'category',
        text: 'issues.attributes.category',
        sortable: true,
        align: 'center',
    },
    {
        value: '@categoryPath',
        text: 'issues.attributes.category',
        selector: 'issues.attributes.categoryPath',
        sortable: true,
        align: 'center',
        width: '20%',
    },
    {
        value: 'assignedTo',
        text: 'issues.attributes.assignedTo',
        sortable: true,
        align: 'center',
    },
    {
        value: 'createdBy',
        text: 'issues.attributes.createdBy',
        sortable: true,
        align: 'center',
    },
    {
        value: 'versionImpact',
        text: 'issues.attributes.versionImpact',
        sortable: true,
        align: 'center',
    },
    {
        value: 'versionWanted',
        text: 'issues.attributes.versionWanted',
        sortable: true,
        align: 'center',
    },
    {
        value: 'versionTarget',
        text: 'issues.attributes.versionTarget',
        sortable: true,
        align: 'center',
    },
    {
        value: 'createdAt',
        text: 'issues.attributes.createdAt',
        sortable: true,
        align: 'center',
    },
    {
        value: 'updatedAt',
        text: 'issues.attributes.updatedAt',
        sortable: true,
        align: 'center',
    },
    {
        value: 'startedAt',
        text: 'issues.attributes.startedAt',
        sortable: true,
        align: 'center',
    },
    {
        value: 'endedAt',
        text: 'issues.attributes.endedAt',
        sortable: true,
        align: 'center',
    },
    {
        value: 'done',
        text: 'issues.attributes.done',
        sortable: true,
        align: 'center',
    },
    {
        value: 'time',
        text: 'issues.attributes.time',
        sortable: true,
        align: 'center',
    },
    {
        value: 'watchers',
        text: 'issues.attributes.watchers',
        sortable: true,
        align: 'center',
    },
    {
        value: 'interesteds',
        text: 'issues.attributes.interesteds',
        sortable: true,
        align: 'center',
    },
    {
        value: 'releaseNote',
        text: 'issues.attributes.releaseNote',
        sortable: true,
        align: 'left',
    },
    {
        value: 'releaseNoteDetail',
        text: 'issues.attributes.releaseNoteDetail',
        sortable: true,
        align: 'center',
    },
    {
        value: 'supportClient',
        text: 'issues.attributes.supportClient',
        sortable: true,
        align: 'center',
    },
    {
        value: 'supportInput',
        text: 'issues.attributes.supportInput',
        sortable: true,
        align: 'center',
    },
    {
        value: 'supportOutput',
        text: 'issues.attributes.supportOutput',
        sortable: true,
        align: 'center',
    },
    {
        value: 'supportDetail',
        text: 'issues.attributes.supportDetail',
        sortable: true,
        align: 'center',
    },
    {
        value: 'tracking',
        text: 'issues.attributes.tracking',
        sortable: true,
        align: 'center',
    },
    {
        value: 'sprint',
        text: 'issues.attributes.sprint',
        sortable: true,
        align: 'center',
    },
];
