<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on }">
            <a :class="classTitle" v-on="on" v-text="formatDate(issue.endedAt)"> </a>
        </template>
        <v-card>
            <v-card-text>
                <selector-date v-model="editingValue" :label="$t('issues.attributes.startedAt')" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDateMixin } from '@/mixins/dates';
import selectorDate from '@/components/selectors/simple/date';
import { parseISO } from 'date-fns';

export default {
    name: 'issue-endedAt',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    mixins: [formatDateMixin],
    components: {
        selectorDate,
    },
    props: {
        issue: {},
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    watch: {
        editing: function (e) {
            if (e) {
                if (this.issue.endedAt) {
                    this.editingValue = parseISO(this.issue.endedAt);
                } else {
                    this.editingValue = new Date();
                }
            }
        },
    },
    computed: {
        ...mapGetters(['project']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'endedAt', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    endedAt: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
