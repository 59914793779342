<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on }">
            <a
                :class="classTitle"
                v-on="on"
                v-html="
                    issue.versionTarget && issue.versionTarget.length > 0 ? issue.versionTarget.join(', &nbsp;') : '-'
                "
            >
            </a>
        </template>
        <v-card>
            <v-card-text>
                <versions-selector
                    :label="$t('issues.attributes.versionTarget')"
                    v-model="editingValue"
                    :in-develop="filtering"
                />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn icon @click="seeAllVersions" v-if="memberRoles.some((r) => ['productManager'].includes(r))">
                    <v-icon v-if="filtering">mdi-eye-off</v-icon>
                    <v-icon v-else>mdi-eye</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                <v-btn color="success" text @click="changeAttribute" v-text="$t('buttons.save')" />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { projectMemberMixin } from '@/mixins/member';
import versionsSelector from '@/components/selectors/multiple/versions';

export default {
    name: 'issue-version-target',
    mixins: [projectMemberMixin],
    data: () => ({
        editing: false,
        editingValue: undefined,
        filtering: true,
    }),
    props: {
        issue: {},
        displayValue: {
            default: false,
        },
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    components: {
        versionsSelector,
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.versionTarget;
            }
        },
    },
    computed: {
        ...mapGetters(['project', 'profile']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        seeAllVersions() {
            if (this.filtering) {
                this.filtering = undefined;
            } else {
                this.filtering = true;
            }
        },
        async changeAttribute(value) {
            if (this.onlyEdit) {
                this.$emit('update-attribute', { name: 'versionTarget', value: this.editingValue });
                this.editing = false;
            } else {
                await this.updateIssue({
                    id: this.issue.id,
                    versionTarget: this.editingValue,
                });

                this.editing = false;
                this.$emit('change-attribute-value');
            }
        },
    },
};
</script>
