<template>
    <v-select v-model="localUser" :items="usersItems" :outlined="outlined" :label="label" chips multiple></v-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';
export default {
    name: 'selector-user',
    data: () => ({
        localUser: undefined,
    }),
    created() {
        if (this.users.length === 0) {
            this.findUsers();
        }
    },
    mounted() {
        this.localUser = this.value;
    },
    props: {
        value: {},
        label: {},
        outlined: {
            default: false,
        },
    },
    watch: {
        localUser: function (value) {
            this.$emit('input', value);
        },
        value: function (value) {
            this.localUser = value;
        },
    },
    computed: {
        ...mapGetters(['projectUsers', 'users']),
        usersItems() {
            return OrderBy(this.projectUsers, ['text'], ['asc']);
        },
    },
    methods: {
        ...mapActions(['findUsers']),
    },
};
</script>
