<template>
    <v-menu
        v-model="editing"
        transition="scale-transition"
        :close-on-content-click="false"
        :nudge-width="100"
        :disabled="!allowEditing"
    >
        <template v-slot:activator="{ on }">
            <a :class="classTitle" v-on="on" v-text="issue.time ? issue.time + ' h' : '-'"></a>
        </template>
        <ValidationObserver ref="observer" v-slot="{ invalid }">
            <v-card>
                <v-card-text>
                    <ValidationProvider :name="$t('issues.attributes.time')" :rules="'decimal'" v-slot="{ errors }">
                        <v-text-field
                            :label="$t('issues.attributes.time')"
                            v-model="editingValue"
                            single-line
                            :error-messages="errors[0]"
                        ></v-text-field>
                    </ValidationProvider>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="editing = false" v-text="$t('buttons.cancel')" />
                    <v-btn
                        :disabled="invalid"
                        color="success"
                        text
                        @click="changeAttribute"
                        v-text="$t('buttons.save')"
                    />
                </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-menu>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'issue-time',
    data: () => ({
        editing: false,
        editingValue: undefined,
    }),
    props: {
        issue: {},
        displayValue: {
            default: false,
        },
        allowEditing: {
            default: true,
        },
        onlyEdit: {
            default: false,
        },
    },
    watch: {
        editing: function (e) {
            if (e) {
                this.editingValue = this.issue.time;
            }
        },
    },
    computed: {
        ...mapGetters(['project']),
        classTitle() {
            const clazz = 'blue-grey--text ';
            return this.$vuetify.theme.dark ? clazz + 'text--lighten-4' : clazz + 'text--darken-2';
        },
    },
    methods: {
        ...mapActions(['updateIssue']),
        async changeAttribute(value) {
            const validate = await this.$refs.observer.validate();
            if (validate) {
                if (this.onlyEdit) {
                    this.$emit('update-attribute', { name: 'time', value: this.editingValue });
                    this.editing = false;
                } else {
                    await this.updateIssue({
                        id: this.issue.id,
                        time: this.editingValue,
                    });

                    this.editing = false;
                    this.$emit('change-attribute-value');
                }
            }
        },
    },
};
</script>
