var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.classContainer},[(!_vm.editing)?_c('v-row',[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[(_vm.displayWatch)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.watchIssue}},[(_vm.isWatcher)?_c('v-icon',{attrs:{"color":"amber darken-2"}},[_vm._v("mdi-star")]):_c('v-icon',[_vm._v("mdi-star-outline")])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":"","dark":"","color":"grey darken-4","disabled":!(_vm.limitSize && _vm.title.length > _vm.limitSize)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.linked)?_c('a',_vm._g({class:_vm.classTitle,domProps:{"textContent":_vm._s(
                            _vm.limitSize && _vm.title.length > _vm.limitSize ? _vm.title.substring(0, _vm.limitSize) + '...' : _vm.title
                        )},on:{"click":_vm.editValue}},Object.assign({}, tooltip))):_vm._e(),(_vm.linked)?_c('a',_vm._g({class:_vm.classTitle,domProps:{"textContent":_vm._s(
                            _vm.limitSize && _vm.title.length > _vm.limitSize ? _vm.title.substring(0, _vm.limitSize) + '...' : _vm.title
                        )},on:{"click":_vm.openIssue}},Object.assign({}, tooltip))):_vm._e()]}}],null,false,1540427175)},[_c('span',{domProps:{"textContent":_vm._s(_vm.title)}})]),_c('v-spacer'),(_vm.displayLike)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.likeIssue}},[(_vm.isLike)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-thumb-up")]):_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1):_vm._e()],1)],1):_vm._e(),(_vm.editing)?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":_vm.$t('issues.attributes.name'),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [(_vm.editing)?_c('v-text-field',{attrs:{"label":_vm.$t('issues.attributes.name'),"single-line":"","counter":"80","error-messages":errors[0],"color":"red"},model:{value:(_vm.editingValue),callback:function ($$v) {_vm.editingValue=$$v},expression:"editingValue"}}):_vm._e()]}}],null,false,2412393425)})],1),_c('v-divider'),(!_vm.onlyEdit)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},domProps:{"textContent":_vm._s(_vm.$t('buttons.cancel'))},on:{"click":function($event){_vm.editing = false}}}),_c('v-btn',{attrs:{"color":"success","text":""},domProps:{"textContent":_vm._s(_vm.$t('buttons.save'))},on:{"click":_vm.changeAttribute}})],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }